export const theme = {
  primary: {
    600: "#1a202c",
    900: "#171923",
  },
  grey: { 300: "#d1d1d1" },
  white: "#fff",
  green: {
    300: "#2C7A7B",
  },
  loader: {
    teal: "#5DDADB",
    purple: "#3437A2",
    pink: "#F78EB6",
    bg: "#171923db",
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "#e1e1e1",
    },
  },
  gradient: {
    blue: {
      300: "#002eb3",
      600: "#002360",
      900: "#051937",
    },
  },
  text: {
    grey: {
      300: "#a0aec0",
      500: "#718096",
    },
  },
  timeline: {
    divider: {
      bg: "#718096",
    },
  },
};

export const globalStyles = `
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

 .planby {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

  /* Layout */
  .planby-layout {
    background-color:#000000 !important;
  }

  /* Line */
  .planby-line {}

  /* Current time */
  .planby-current-time {
    background-color:#FFFFFF !important;
  }
  .planby-current-content {
    background-color:#000000 !important;
  }
  .planby-corner-box{
    background-color:#000000 !important;
  }
  /* Channels */
  .planby-channels {
    background-color:#000000 !important;
  }

  /* Channel */
  .planby-channel {
    background-color:#000000 !important;
  }

  /* Program */
  .planby-program {
    background-color:#000000 !important;
  }
  .planby-program-content {
    background-color:#000000 !important;
    background: none !important;
  }
  .planby-program-flex {}
  .planby-program-stack {}
  .planby-program-title {}
  .planby-program-text {}

  /* Timeline */
  .planby-timeline-wrapper {
    background-color:#000000 !important;
  }
  .planby-timeline-box {
    background-color:#000000 !important;
  }
  .planby-timeline-time {
    background-color:#000000 !important;
  }
  .planby-content {
    background-color:#000000 !important;
  }
}
  
`;
