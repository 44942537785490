export const getChannelData = async () => {
  let bodyContent = new FormData();

  const today = new Date().toISOString().slice(0, 10);

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // bodyContent.append("date", "2024-07-24");
  bodyContent.append("date", today);
  bodyContent.append("time_zone", tz);

  let response = await fetch(
    `${process.env.REACT_APP_API_URL}fetch-tv-programs`,
    {
      method: "POST",
      body: bodyContent,
    }
  );

  let data = await response.json();
  // console.log(data);
  return data;
};

export const getGroupData = async (id) => {
  let bodyContent = new FormData();

  const today = new Date().toISOString().slice(0, 10);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  bodyContent.append("date", today);
  bodyContent.append("domain_id", id);
  bodyContent.append("time_zone", tz);

  let response = await fetch(
    `${process.env.REACT_APP_API_URL}fetch-tv-programs`,
    {
      method: "POST",
      body: bodyContent,
    }
  );

  let data = await response.json();
  // console.log(id, data);
  return data;
};

export const getWebsiteData = async (id) => {
  let bodyContent = new FormData();

  const today = new Date().toISOString().slice(0, 10);

  bodyContent.append("date", today);
  bodyContent.append("website_id", id);

  let response = await fetch(
    `${process.env.REACT_APP_API_URL}get-public-website`,
    {
      method: "POST",
      body: bodyContent,
    }
  );

  let data = await response.json();
  console.log(id, data);
  return data;
};
