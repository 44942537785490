import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CustomHeader from "./CustomHeader";
import Watch from "./Watch";
import CustomFooter from "./CustomFooter";
import { Helmet } from "react-helmet";
import { useTheme, useThemeUpdate } from "../../../layout/provider/Theme";
import { getWebsiteData } from "../../../utils/channelEpg";

const changeFavicon = (faviconURL) => {
  let link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = faviconURL;
  document.getElementsByTagName("head")[0].appendChild(link);
};

function WatchLayout() {
  const { slug, id } = useParams();
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    getWebsiteData(id).then((data) => {
      if (data.status) {
        if (data.data[0]) {
          setData(data.data[0]);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (data) {
      changeFavicon(data.site_favicon);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (!slug) {
      window.location = process.env.REACT_APP_PUBLIC_URL;
    }
  }, []);

  useEffect(() => {
    themeUpdate.customLayout(true);
  }, [theme.fullScreen]);

  if (isLoading) {
    return (
      <div className="loader-spinner-custom d-flex justify-content-center align-items-center">
        {/* <Spinner color="dark" /> */}
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <CustomHeader fixed logo={data.site_logo} title={data.title} />
      <Watch slug={slug} did={id} />
      <CustomFooter text={data.footer_text} />
    </>
  );
}

export default WatchLayout;
