import React, { useState, useRef, useEffect } from "react";
import "./footer.css";
import {
  d_home_logo,
  Homelightlogo,
  d_plus_logo,
  img,
  streamdeck_logo,
  tv_logo,
  silotalk_logo,
  silogame,
  storage_logo,
  qrcode_logo,
  marketplace_apps,
  store_apps,
  homelogo,
  mail_apps,
  sitebuilder_logo,
  calender_apps,
  community_apps,
  d_viewer_apps,
  silo_publisher_apps,
} from "../../images/applogos";
import {
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
  Progress,
  Spinner,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import TalkComponent from "../../TalkComponent.jsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { useTheme } from "../provider/Theme";
import { Icon } from "../../components/Component";
// import ImageComponent from "../../pages/ImageComponent";
import { fetchAPI, getCookie } from "../../utils/Utils";
import useStorageUsage from "../../hooks/useStorageUsage.jsx";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

const Footer = () => {
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen({
      ...tooltipOpen,
      [index]: !tooltipOpen[index],
    });
  };

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = getCookie("authToken");
    setAuthenticated(isAuthenticated);
  }, []);

  const authToken = getCookie("authToken");
  const { storageDetails, loading } = useStorageUsage();
  const [footerApps, setFooterApps] = useState(
    []
    //   [
    //   {
    //     imgSrc: store_apps,
    //     title: "Silo Apps",
    //     footer_visibility: 1,
    //     link: "/apps",
    //     useNavigate: true,
    //     key: 15,
    //     popOver: false,
    //     showIcon: false,
    //   },
    //   {
    //     imgSrc: streamdeck_logo,
    //     title: "Streamdeck",
    //     footer_visibility: 1,
    //     link: "https://streamdeck.silocloud.io",
    //     key: 4,
    //     popOver: false,
    //     useNavigate: false,
    //     showIcon: false,
    //   },
    //   {
    //     imgSrc: tv_logo,
    //     title: "TV",
    //     link: "https://tv.silocloud.io",
    //     key: 12,
    //     footer_visibility: 1,
    //     popOver: false,
    //     useNavigate: false,
    //     showIcon: false,
    //   },
    //   {
    //     imgSrc: silotalk_logo,
    //     title: "SiloTalk",
    //     // link: `https://silotalk.com/token-login/${authToken}`,
    //     link: getCookie("authToken")
    //       ? `https://silotalk.com/token-login/${getCookie("authToken")}`
    //       : `https://silotalk.com`,
    //     key: 11,
    //     footer_visibility: 1,
    //     popOver: false,
    //     useNavigate: false,
    //     showIcon: false,
    //   },
    //   {
    //     imgSrc: storage_logo,
    //     title: "Storage",
    //     link: "https://storage.silocloud.io",
    //     key: 7,
    //     footer_visibility: 1,
    //     popOver: false,
    //     useNavigate: false,
    //     showIcon: false,
    //   },
    //   {
    //     imgSrc: mail_apps,
    //     title: "Mail",
    //     link: "https://mail.silocloud.io/",
    //     key: 10,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    //   {
    //     imgSrc: qrcode_logo,
    //     title: "QR",
    //     link: "https://qr.silocloud.io",
    //     key: 2,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    //   {
    //     imgSrc: marketplace_apps,
    //     title: "Store",
    //     link: "https://store.silocloud.io",
    //     key: 5,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    //   {
    //     imgSrc: sitebuilder_logo,
    //     title: "SiteBuilder",
    //     link: "https://site.silocloud.com/",
    //     key: 9,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    //   {
    //     imgSrc: calender_apps,
    //     title: "Calendar",
    //     link: "https://calendar.silocloud.io/",
    //     key: 8,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    //   {
    //     imgSrc: community_apps,
    //     title: "Community",
    //     link: `${process.env.REACT_APP_PROJECT_OLD_LINK_NAME}cloud-community`,
    //     key: 1,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    //   {
    //     imgSrc: d_viewer_apps,
    //     title: "3D Viewer",
    //     link: `https://3d.silocloud.io/`,
    //     key: 6,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    //   {
    //     imgSrc: silo_publisher_apps,
    //     title: "Publisher",
    //     link: `https://publisher.silocloud.io/`,
    //     key: 3,
    //     footer_visibility: 2,
    //     popOver: true,
    //     useNavigate: false,
    //     showIcon: true,
    //   },
    // ]
  );

  const theme = useTheme();
  const popoverRef = useRef();
  const popoverTargetRef = useRef();
  const [loadingApp, setLoadingApp] = useState(null);

  const toggleImageInFooter = async (image, key, value) => {
    setLoadingApp(key);
    const Data = new FormData();
    Data.append("columnValue", value);
    if (!authenticated) {
      toast.error("Please login first");
      setLoadingApp(null);
      return;
    }
    try {
      Data.append("action", "update");
      Data.append("column", "apps");
      Data.append("columnKey", key);
      const addApps = await fetchAPI(Data, "api/v1/public/frontend-settings");
      if (addApps.status) {
        setFooterApps((prevFooterApps) =>
          prevFooterApps.map((app) =>
            app.key === key ? { ...app, footer_visibility: value } : app
          )
        );
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error updating footer app visibility:", error);
    } finally {
      setLoadingApp(null);
    }
  };

  useEffect(() => {
    fetchFooterApps();
  }, []);

  // const fetchFooterApps = async () => {
  //   try {
  //     const Data = new FormData();
  //     Data.append("action", "fetch");
  //     const response = await fetchAPI(Data, "api/v1/public/frontend-settings");
  //     if (response.status) {
  //       setFooterApps((prevFooterApps) =>
  //         prevFooterApps.map((app) => {
  //           let tempApp = response?.data?.frontend_settings?.apps[app.key - 1] || null;
  //           return tempApp ? { ...app, footer_visibility: tempApp.footer_visibility } : app;
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching footer apps:", error);
  //   }
  // };
  const fetchFooterApps = async () => {
    try {
      const Data = new FormData();
      Data.append("action", "fetch");
      // const Data = {
      //   action: "fetch",
      // };
      const response = await fetchAPI(Data, "api/v1/public/frontend-settings");
      // console.log(response.data?.frontend_settings?.apps, "23");
      if (response.status) {
        setFooterApps(
          response?.data?.frontend_settings?.apps.map((app) => ({
            ...app,
            imgSrc: `https://api.silocloud.io/${app.imgSrc}`, // Directly use the URL here
            link:
              app.title === "Talk"
                ? `${app.link}/token-login/${authToken}`
                : `${app.link}`,
          }))
        );
        // setFooterApps((prevFooterApps) =>
        //   prevFooterApps.map((app) => {
        //     let tempApp = response?.data?.frontend_settings?.apps[app.key - 1] || null;
        //     return tempApp ? { ...app, footer_visibility: tempApp.footer_visibility } : app;
        //   })
        // );
      }
    } catch (error) {
      console.error("Error fetching footer apps:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      popoverTargetRef.current &&
      !popoverTargetRef.current.contains(event.target) &&
      !event.target.closest(".footerlogosPop-container")
    ) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [modal, setModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setModal(!modal);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  if (theme.fullScreen || theme.customLayout) {
    return null;
  }

  return (
    <>
      <section className="section-footer w-100 position-fixed bottom-0 start-0 py-1">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-2 col-lg-3 px-0 px-lg-3 my-auto">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                <Link to="/" className="home_icon">
                  <img src={homelogo} alt="" className="homeicon" />
                </Link>
                <div className="storage-block rounded-3 px-2 py-1 ms-1 d-none d-lg-block">
                  <Link to="https://storage.silocloud.io/">
                    <div className="d-flex align-items-baseline mb-1">
                      <Icon name="db-fill" className="fs-3" />
                      {!loading ? (
                        <p className="fs-9px mb-0 ms-1 opacity-75">
                          Cloud Storage {storageDetails.usedStorageText}/
                          {storageDetails.planStorageText} Used
                        </p>
                      ) : (
                        <Skeleton width={100} height={8} baseColor="#c5bfe0" />
                      )}
                    </div>
                    <div className="">
                      <Progress
                        value={storageDetails.percentageUsed}
                        color="info"
                        className="progress-storage"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-9 col-lg-7 my-auto responsive-mobile">
              <div className="d-flex justify-content-center align-items-center">
                {footerApps.map((image, index) => {
                  let tempfooterApp =
                    image.footer_visibility == "1" || !image.popOver;

                  return (
                    tempfooterApp && (
                      <div
                        key={index}
                        className="text-center me-1 me-lg-2 pointer"
                        onClick={() => {
                          if (image.useNavigate) {
                            navigate("/apps");
                          } else {
                            window.location.href = image.link;
                          }
                        }}
                      >
                        {!image.imgSrc ? (
                          <Spinner size="sm" className="mt-1 spinner-md" />
                        ) : (
                          <div
                            className={`position-relative footer-apps ${
                              popoverOpen ? "footerlogosPop-container" : ""
                            }`}
                          >
                            <img
                              src={image.imgSrc}
                              id={`footer-logo-${index}`}
                              alt={`Footer Logo ${index}`}
                              className="footerlogos"
                              height={image.title === "Mail" ? "24px" : "28px"}
                            />
                            {image.showIcon ? (
                              <div
                                className={`plus-minus-icon-${image.key} ${
                                  popoverOpen ? "minus-icon" : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleImageInFooter(image, image.key, "2");
                                }}
                              >
                                <FaMinusCircle className="plus-minus-icon" />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}

                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[index]}
                          target={`footer-logo-${index}`}
                          toggle={() => toggleTooltip(index)}
                        >
                          {image.title}
                        </Tooltip>
                      </div>
                    )
                  );
                })}

                <div className="bg-icon-plus ms-1" ref={popoverTargetRef}>
                  <img
                    src={d_plus_logo}
                    alt=""
                    height="15"
                    id="PopoverTop"
                    onClick={togglePopover}
                  />
                </div>

                <Popover
                  placement="top"
                  isOpen={popoverOpen}
                  target="PopoverTop"
                  toggle={togglePopover}
                  innerRef={popoverRef}
                  className="popover-images"
                >
                  <PopoverBody>
                    {footerApps.length === 0 ||
                    footerApps.every(
                      (image) => image.popOver || image.footer_visibility == "2"
                    ) ? (
                      <div className="text-center">
                        <img src={d_plus_logo} alt="default" height="28px" />
                        <p className="fs-9px mt-1 opacity-75 mb-0">Apps</p>
                      </div>
                    ) : (
                      footerApps.map((image, index) => {
                        return (
                          image.popOver &&
                          image.footer_visibility == "2" && (
                            <div
                              key={index}
                              className="footerlogosPop-container text-center pointer"
                              onClick={() => {
                                toggleImageInFooter(image, image.key, 1);
                              }}
                            >
                              {loadingApp === image.key ? (
                                <Spinner
                                  size="sm"
                                  className="mb-2 spinner-md"
                                />
                              ) : (
                                <>
                                  <div className="position-relative footer-apps">
                                    <img
                                      src={image.imgSrc}
                                      alt={`Popover Logo ${index}`}
                                      className="footerlogosPop"
                                      style={image.style}
                                      height={
                                        image.title === "Mail" ? "22px" : "28px"
                                      }
                                    />
                                    <div
                                      className={` plus-minus-icon-${image.key}`}
                                    >
                                      <FaPlusCircle
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleImageInFooter(
                                            image,
                                            image.key,
                                            1
                                          );
                                        }}
                                        className="plus-minus-icon"
                                      />
                                    </div>
                                  </div>
                                  <p className="fs-9px mt-1 opacity-75 mb-0">
                                    {image.title}
                                  </p>
                                </>
                              )}
                            </div>
                          )
                        );
                      })
                    )}
                  </PopoverBody>
                </Popover>
              </div>
            </div>

            <div className="col-1 col-lg-2 px-0 px-lg-3 mobile-view  my-auto">
              <div className="d-lg-none">
                <UncontrolledDropdown direction="up">
                  <DropdownToggle
                    className="btn-action p-0 border-0 bg-transparent"
                    color="primary"
                  >
                    <span>
                      <Icon name="menu" className="theme-menu" />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt">
                      <li className="py-2">
                        {/* {authenticated && <TalkComponent />} */}
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center footer-bottom justify-content-center ">
                          <Link
                            to="https://silocloud.com/"
                            className="mb-0 fs-9px"
                          >
                            About
                          </Link>
                          <Link to="/privacy-policy" className="mb-0 fs-9px">
                            Privacy
                          </Link>
                          <Link to="/terms-condition" className="mb-0 fs-9px">
                            Terms
                          </Link>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="storage-block rounded-3 px-2 py-1 ms-1">
                          <Link to="https://storage.silocloud.io/">
                            <div className="d-flex align-items-baseline mb-1">
                              <Icon name="db-fill" className="fs-3" />
                              {!loading ? (
                                <p className="fs-9px mb-0 ms-1 opacity-75">
                                  Cloud Storage {storageDetails.usedStorageText}
                                  /{storageDetails.planStorageText} Used
                                </p>
                              ) : (
                                <Skeleton
                                  width={100}
                                  height={8}
                                  baseColor="#c5bfe0"
                                />
                              )}
                            </div>
                            <div className="">
                              <Progress
                                value={storageDetails.percentageUsed}
                                color="info"
                                className="progress-storage"
                              />
                            </div>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="d-none d-lg-block">
                {/* {authenticated && <TalkComponent />} */}
                <div className="d-flex align-items-center footer-bottom justify-content-center justify-content-lg-end">
                  <Link to="https://silocloud.com/" className="mb-0 fs-9px">
                    About
                  </Link>
                  <Link to="/privacy-policy" className="mb-0 fs-9px">
                    Privacy
                  </Link>
                  <Link to="/terms-condition" className="mb-0 fs-9px">
                    Terms
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ImageComponent modal={modal} toggle={toggle} /> */}
    </>
  );
};

export default Footer;
