import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "../pages/homepage/pages/HomePage.jsx";
import Layout from "../layout/Index";
import Cookies from "js-cookie";
import WatchLayout from "../pages/homepage/pages/WatchLayout.jsx";
import VideoPlayer from "../pages/homepage/pages/VideoPlayer.jsx";
const Pages = () => {
  // const location = useLocation();
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  // const [authChecking, setAuthChecking] = useState(true);
  // useEffect(() => {
  //   let at = Cookies.get("authToken");
  //   if (!at) {
  //     const now = new Date();
  //     const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  //     Cookies.set("currentURL", window.location.href, {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //       expires: expires,
  //     });
  //     window.location = process.env.REACT_APP_ACCOUNT_URL;
  //   } else {
  //     setAuthChecking(false);
  //     Cookies.remove("currentURL", {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //     });
  //   }
  // }, []);
  // if (authChecking) return <>Redirecting to authentication</>;
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="player" element={<VideoPlayer />} />
        <Route index={true} element={<HomePage />}></Route>
        <Route path="channel/:channelName" element={<HomePage />} />
        {/* <Route path="/test" element={<Test />}></Route> */}
        <Route path="watch/:slug/:id" element={<WatchLayout />}></Route>
        {/* <Route path=":slug" element={<WatchLayout />} /> */}
        <Route path="*" element={<HomePage />} />
      </Route>
    </Routes>
  );
};
export default Pages;
