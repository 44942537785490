import React from "react";
import classNames from "classnames";
import { useTheme, useThemeUpdate } from "../../../layout/provider/Theme";
import { Icon } from "../../../components/Component";
import Toggle from "../../../layout/sidebar/Toggle";
import "../../../css/common.css";

function CustomHeader({ fixed, className, logo, title }) {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]:
      theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  const toggleFullScreen = () => {
    // setFullScreenOn(!fullScreenOn);
    themeUpdate.fullScreen(!theme.fullScreen);

    themeUpdate.customLayout(true);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const handleThemeToggle = (ev) => {
    ev.preventDefault();
    themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
  };

  if (theme.fullScreen) {
    return null;
  }

  return (
    <section className="section-header">
      <div className={`${headerClass} border-0`}>
        <div className="container-fluid position-relative">
          <div className="nk-header-wrap align-items-center px-2">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon"
                icon="menu"
                click={themeUpdate.sidebarVisibility}
              />
            </div>
            <div className="nk-header-app-name">
              <div className="header-logo py-1">
                <img
                  height={40}
                  style={{ marginRight: "5px", objectFit: "contain" }}
                  src={logo}
                />
              </div>
              <div className="nk-header-app-info fs-6 ms-1 nk-header-app-info applogotext">
                <p
                  className="mb-0"
                  style={{ fontWeight: 500, fontSize: "1.1rem" }}
                >
                  {title}
                </p>
                {/* <p style={{ fontSize: "0.8rem" }}>by SiloCloud</p> */}
              </div>
            </div>

            {/* <div className="d-flex justify-content-center header-calendar">
              <div style={{ lineHeight: "17px" }}>
                <p className="mb-0 fw-bolder text-blue-color">{time}</p>
                <p className="fs-9px mb-0">{date}</p>
              </div>
              <div className="ms-1">
                <Link to="https://calendar.silocloud.io/">
                  <Icon name="calender-date" className="head-icon" />
                </Link>
              </div>
            </div> */}

            <div className="nk-header-tools align-items-center py-1">
              <ul className="nk-quick-nav flex">
                <li className="px-4" style={{ paddingTop: "3px" }}>
                  <a
                    // className={`dark-switch ${
                    //   theme.skin === "dark" ? "active" : ""
                    // }`}
                    href="#"
                    onClick={handleThemeToggle}
                  >
                    {theme.skin === "dark" ? (
                      <div
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        <em className="icon ni ni-sun"></em>
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        <em className="icon ni ni-moon"></em>
                      </div>
                    )}
                  </a>
                </li>
                <li className="mt-1">
                  {theme.fullScreen ? (
                    <Icon
                      name="minimize"
                      className="pointer fs-4"
                      onClick={toggleFullScreen}
                    />
                  ) : (
                    <Icon
                      name="maximize"
                      className="pointer fs-4"
                      onClick={toggleFullScreen}
                    />
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomHeader;
