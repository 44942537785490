import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import "../../../css/tvsilocloud/app.css";
import {
  useEpg,
  Epg,
  Layout,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  useProgram,
  ProgramImage,
  ChannelBox,
  ChannelLogo,
} from "@nessprim/planby-pro";
import { globalStyles } from "../components/theme";
import { getCurrentlyStreamingObject } from "../../helpers/helpers";
import "./epgComponent.css";

import tv_logo from "../../../images/applogos/tv_logo.png";

import { useTheme, useThemeUpdate } from "../../../layout/provider/Theme";

import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

import { v4 as uuidv4 } from "uuid";
import { Icon } from "../../../components/Component";

const CustomItem = ({ currentChannel, channels, program, ...rest }) => {
  // const [liveStream, setLiveStream] = useState(false);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const popId = useRef(uuidv4()).current;

  const { isLive, isMinWidth, styles, formatTime, set12HoursTimeFormat } =
    useProgram({
      program,
      ...rest,
    });

  const { data } = program;

  // if (isLive) console.log(data);

  const { image, title, since, till, id, channel_id, duration, program_type } =
    data;

  const liveStream = checkLive(channel_id, channels);

  function checkLive(channel_id, channels) {
    // Find the channel object with the given channel_id
    const channel = channels.find(
      (channel) => channel.channel_id === channel_id
    );

    // Check if the channel is found and if it's live
    return channel ? channel.is_live : false;
  }

  // console.log(
  //   channel_id == currentChannel.channel_id,
  //   channel_id,
  //   currentChannel.channel_id
  // );

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  let backgoundColorCustomItem = undefined;
  let textColor = undefined;
  let borderColor = undefined;

  const { skin } = useTheme();

  const [className, setClassName] = useState("");
  const [newClasses, setNewClasses] = useState("");
  let newClassName = "";

  useEffect(() => {
    setNewClasses("");
    if (duration < 120 && program_type === "programad") {
      setNewClasses("lessdurationprogram " + program_type);
      newClassName += "lessdurationprogram " + program_type;
    } else {
      setNewClasses(program_type + " ");
      newClassName += program_type + " ";
    }
    if (channel_id == currentChannel.channel_id && isLive) {
      setNewClasses((prev) => prev + " playing-live live-epg");
      textColor = "white";
    } else if (channel_id != currentChannel.channel_id && isLive) {
      setNewClasses((prev) => prev + " only-live live-epg");
      textColor = "white";
    }
  }, [duration, isLive, currentChannel]);

  useEffect(() => {
    setClassName(newClasses);
  }, [newClasses]);

  if (channel_id == currentChannel.channel_id && isLive) {
    // setLiveStream(true);
    if (skin === "light") {
      backgoundColorCustomItem = "#fff";
      borderColor = "#000";
    } else {
      backgoundColorCustomItem = "#172738";
      borderColor = "#fff";
    }
    // textColor = "black";
  } else if (channel_id != currentChannel.channel_id && isLive) {
    if (skin === "light") {
      backgoundColorCustomItem = "#e0e0ea";
      borderColor = "#fff";
    } else {
      backgoundColorCustomItem = "#111b26";
      textColor = "white";
    }
    // textColor = "white";
  }

  const islivestyles = {
    container: {
      // background: isLive ? "" : "#ffffff14",
      // borderColor: isLive ? borderColor : "transparent",
      // borderWidth: "1px",
      // borderStyle: "solid",
      borderColor: "transparent",
      opacity: isLive ? "" : "0.85",
      cursor: isLive ? "pointer" : "default",
      // background: backgoundColorCustomItem,
    },
  };

  return (
    <>
      <ProgramBox
        width={styles.width}
        className="increase scrollbarWidth"
        style={{
          ...styles.position,
          background: "transparent",
          paddingInline: "1px",
        }}
      >
        <ProgramContent
          width={{ ...styles.width }}
          style={{ ...islivestyles.container, padding: 0, paddingRight: "5px" }}
          isLive={isLive}
          id={`Popover${popId}`}
          className={className}
          // id={popId}
          onMouseEnter={(e) => setPopoverOpen(true)}
          onMouseLeave={(e) => setPopoverOpen(false)}
        >
          <ProgramFlex style={{ alignItems: "center" }}>
            {isLive && isMinWidth ? (
              <ProgramImage
                src={image}
                alt="Preview"
                style={{
                  margin: 0,
                  height: "100%",
                  width: "100px",
                  borderRadius: "0",
                  objectFit: "cover",
                }}
              />
            ) : (
              <span></span>
            )}
            <ProgramStack style={{ marginLeft: "9px" }}>
              <ProgramTitle style={{ color: textColor, fontSize: "12px" }}>
                {liveStream && (
                  <p
                    style={{
                      display: "inline",
                      color: "white",
                      background: "red",
                      padding: "0px 4px 1px 4px",
                      marginRight: "5px",
                      borderRadius: "3px",
                      fontWeight: "600",
                    }}
                  >
                    Live
                  </p>
                )}
                {title}
              </ProgramTitle>
              <ProgramText style={{ fontSize: "10px" }}>
                {/* {sinceTime} - {tillTime} */}
                {formatTimes(since, till)}
              </ProgramText>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
      </ProgramBox>
      <Popover
        isOpen={popoverOpen}
        placement="top"
        target={`Popover${popId}`}
        // target={popId}
        toggle={toggle}
        style={{
          zIndex: "2000 !important",
          overflow: "hidden",
        }}
      >
        <PopoverHeader style={{ fontSize: ".8rem", fontWeight: "500" }}>
          {title}
        </PopoverHeader>
        <PopoverBody
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0",
          }}
        >
          <div
            style={{
              height: "100px",
              width: "170px",
            }}
          >
            <img
              src={image}
              alt={title}
              className="img-flid"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="mt-1" style={{ fontSize: ".7rem" }}>
            {/* {formatTimes(since, till)} */}
            {sinceTime} - {tillTime}
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

const formatTimes = (timestamp1, timestamp2) => {
  // Calculate the difference in milliseconds
  const diffInMillis = Math.abs(new Date(timestamp2) - new Date(timestamp1));

  // Convert milliseconds to hours, minutes, and seconds
  const diffInSeconds = Math.floor(diffInMillis / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  const seconds = diffInSeconds % 60;

  // Format the duration string
  let formattedDuration;
  if (hours > 0) {
    formattedDuration = `${hours}h ${minutes}m`;
  } else if (minutes > 0) {
    formattedDuration = `${minutes}m`;
  } else {
    formattedDuration = `${seconds}s`;
  }

  return formattedDuration;
};

const formatTimes2 = (timestamp1, timestamp2) => {
  function extractTime(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  const time1 = extractTime(timestamp1);
  const time2 = extractTime(timestamp2);

  return `${time1} - ${time2}`;
};

function Line({ ref2, styles }) {
  return (
    <div
      ref={ref2}
      className="shadow "
      style={{
        ...styles.position,
        width: "2px",
        background: "#f40404e6",
        pointerEvents: "none",
      }}
    />
  );
}

function EpgComponent({
  epg,
  channels,
  setCurrentChannel,
  isLoading,
  currentChannel,
}) {
  // console.log(epg, channels);
  const theme = useTheme();
  const { skin } = useTheme();
  const themeUpdate = useThemeUpdate();

  const CustomChannelItem = ({ channel }) => {
    const { position, logo } = channel.channel;

    let uuid = channel.channel["uuid"];

    const isLive = channel.channel.channel_id == currentChannel.channel_id;

    const islivestyles = {
      container: {
        background: isLive ? "#222" : "black",
        borderColor: isLive ? "#ffffff55" : "transparent",
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderRadius: "5px",
        opacity: isLive ? "" : "1",
        cursor: isLive ? "pointer" : "default",
      },
    };

    const islivestylesLight = {
      container: {
        background: isLive ? "white" : "#eee",
        borderColor: isLive ? "#000" : "#fff",
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderRadius: "5px",
        opacity: isLive ? "" : "1",
        cursor: isLive ? "pointer" : "default",
      },
    };

    return (
      <ChannelBox
        key={uuid}
        className="shedulerCard pointer channel-box"
        {...position}
        // style={islivestylesLight.container}
        style={
          skin === "light"
            ? islivestylesLight.container
            : islivestyles.container
        }
        onClick={() => {
          setCurrentChannel(channel.channel);
        }}
      >
        <ChannelLogo
          src={logo || tv_logo}
          alt="Logo"
          style={{ width: "50px", height: "40px", objectFit: "contain" }}
          className="channel-logo"
        />
        <div className="tooltip2">
          <p>{channel.channel.channel_name}</p>
        </div>
      </ChannelBox>
    );
  };

  const theme2 = {
    primary: {
      600: "#e1e5ec", // light grey
      900: "#cbd5e0", // lighter grey
    },
    grey: { 300: "#1e1f20" }, // medium grey
    white: "#f0f4f8", // very light grey
    teal: {
      100: "#b2d8d8", // light teal with more grey
    },
    green: {
      200: "#a3c4a8", // light green with greyish tone
      300: "#7a9c8a", // medium green with greyish tone
    },
    loader: {
      teal: "#b2d8d8", // light teal with more grey
      purple: "#a69bbd", // medium purple with more grey
      pink: "#caa1b1", // light pink with more grey
      bg: "#e5e7eb", // light background
    },
    scrollbar: {
      border: "#a0aec0", // medium grey
      thumb: {
        bg: "#e1e5ec", // light grey
      },
    },
    gradient: {
      blue: {
        300: "#e0eaf0", // light blue with whitish tone
        600: "#d1dce2", // medium blue with whitish tone
        900: "#c3ced4", // darker blue with whitish tone
      },
    },
    text: {
      grey: {
        300: "#2d3748", // darker grey
        500: "#1a202c", // even darker grey
      },
    },
    timeline: {
      divider: {
        bg: "#cbd5e0", // light grey
      },
    },
    grid: {
      item: "#e2e8f0", // very light grey
      divider: "#cbd5e0", // light grey
      highlight: "#b2d8d8", // light teal with greyish tone
    },
  };

  useEffect(() => {
    onScrollToNow();
    // console.log(redLineRef, redLineRef.current, epgRef, epgRef.current);
    // if (redLineRef.current) {
    //   const redLineLeft = redLineRef.current.offsetLeft;
    //   console.log(redLineLeft);
    //   return;
    // }
  }, [currentChannel]);

  const { getEpgProps, getLayoutProps, onScrollToNow, scrollX } = useEpg({
    epg,
    channels,
    startDate: new Date().toISOString().slice(0, 10) + "T00:00:00",
    endDate: new Date().toISOString().slice(0, 10) + "T24:00:00",
    isInitialScrollToNow: true,
    isLine: true,
    liveRefreshTime: 120,
    globalStyles,
    scrollY: 0,
    itemHeight: 70,
    // timezone: {
    //   enabled: true,
    //   mode: "utc",
    //   zone: "UTC",
    // },
    ...(skin === "light" ? { theme: theme2 } : {}),
  });

  const initialScrollXRef = useRef(null);
  const [showLeftIndicator, setShowLeftIndicator] = useState(false);
  const [showRightIndicator, setShowRightIndicator] = useState(false);
  const [isInitialScrollDone, setIsInitialScrollDone] = useState(false);

  useEffect(() => {
    if (!isLoading && !isInitialScrollDone) {
      // Delay to ensure automatic scroll and data loading have completed
      const timer = setTimeout(() => {
        if (scrollX) {
          initialScrollXRef.current = scrollX;
          setIsInitialScrollDone(true);
        }
      }, 800); // Increase delay if necessary

      return () => clearTimeout(timer);
    }
  }, [isLoading, scrollX, isInitialScrollDone]);

  useEffect(() => {
    // console.log(scrollX, initialScrollXRef.current);
    if (initialScrollXRef.current === null || !isInitialScrollDone) return;

    const windowWidth = window.innerWidth;
    const halfWindowWidth = windowWidth / 2;

    if (scrollX > initialScrollXRef.current + halfWindowWidth - 100) {
      setShowLeftIndicator(true);
    } else {
      setShowLeftIndicator(false);
    }

    if (scrollX < initialScrollXRef.current - halfWindowWidth + 12) {
      setShowRightIndicator(true);
    } else {
      setShowRightIndicator(false);
    }
  }, [scrollX, isInitialScrollDone]);

  return (
    <>
      {showLeftIndicator && (
        <div className="indicator left-indicator" onClick={onScrollToNow}>
          <Icon name="chevrons-left ind-icon"></Icon>
          {/* <span className="arrow">◀</span> */}
          <span>Live</span>
        </div>
      )}
      {showRightIndicator && (
        <div className="indicator right-indicator" onClick={onScrollToNow}>
          <span>Live</span>
          {/* <span className="arrow">▶</span> */}
          <Icon name="chevrons-right ind-icon"></Icon>
        </div>
      )}
      <Epg {...getEpgProps()} isLoading={isLoading}>
        <Layout
          {...getLayoutProps()}
          renderChannel={(channel) => {
            return (
              <CustomChannelItem key={channel.channel.uuid} channel={channel} />
            );
          }}
          renderProgram={({ program, ...rest }) => {
            return (
              <CustomItem
                key={program.data.since + program.data.channelUuid}
                currentChannel={currentChannel}
                channels={channels}
                program={program}
                {...rest}
              />
            );
          }}
          renderLine={(props) => <Line {...props} />}
        />
      </Epg>
    </>
  );
}

export default EpgComponent;
