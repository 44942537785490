// export const channelData = [
//   {
//     channel_name: "The Siloclouds",
//     logo: "https://api.silocloud.io/get-file-content/users/private/13829/streamdeck/logo-javascript.png?expires=1720519236&signature=320bc722cc15166e213de27ee7c241e95491dcc4d5d01d60903c2fcf7332c38b",
//     uuid: "c95a0d22-17c4-4cb5-9cae-d0f243eb4ab4",
//     channel_id: 20,
//   },
//   {
//     channel_name: "Untitled Channel (6)",
//     logo: null,
//     uuid: "775af6bb-d218-40bf-9e8c-a823284b08e0",
//     channel_id: 22,
//   },
//   {
//     channel_name: "Untitled Channel (5)",
//     logo: "https://api.silocloud.io/get-file-content/users/private/13888/streamdeck/183Single_nft1695029798639.png?expires=1720519236&signature=1aec8461ee58c77cc3a9faa849cfcba5bc4e876c609225f3fc82202d5f36a69b",
//     uuid: "5e766773-4131-459c-9b89-76dcb9bf09db",
//     channel_id: 21,
//   },
//   {
//     channel_name: "Untitled Channel (8)",
//     logo: null,
//     uuid: "ebe8ef99-77a4-45b1-86e6-a091e0603da4",
//     channel_id: 24,
//   },
// ];

// export const epgData = [
//   {
//     id: "feb0f20f-aba9-49c3-9c1a-4fd871645b53",
//     title: "Night cities 4k With Piano Music",
//     image:
//       "https://api.silocloud.io/get-file-content/users/private/13829/video/thumbnails/Night%20cities%204k%20With%20Piano%20Music.jpg?expires=1720503196&signature=27f125322fc4f43c317a3e276220b77c0885d7930173a960d44d55d0c16bd1e1",
//     since: "2024-07-12 09:45:00",
//     till: "2024-07-12 10:46:54",
//     date: "2024-07-12",
//     channelUuid: "c95a0d22-17c4-4cb5-9cae-d0f243eb4ab4",
//     duration: "3714",
//     file_path: "users/private/13829/video/Night cities 4k With Piano Music.mp4",
//     user_id: "13829",
//     channel_id: "20",
//     channelIndex: 0,
//     channelPosition: {
//       top: 0,
//       height: 80,
//     },
//     index: 0,
//     public_file_path:
//       "scheduledvideos/13829/20/2024-07-12/Night cities 4k With Piano Music.mp4",
//   },
//   {
//     id: "57c94a62-394b-493d-87b3-3bde464c9bf7",
//     title:
//       "Lecture 2  Operators and Conditional Statements  JavaScript Full Course",
//     image:
//       "https://api.silocloud.io/get-file-content/users/private/13829/video/thumbnails/Lecture%202%20%20Operators%20and%20Conditional%20Statements%20%20JavaScript%20Full%20Course.jpg?expires=1720503196&signature=4a9b3ef2b387f9803c5e34d9449babbd4d37ff63b92be89e31419cfb99794ac5",
//     since: "2024-07-12 11:15:00",
//     till: "2024-07-12 12:31:46",
//     date: "2024-07-12",
//     channelUuid: "c95a0d22-17c4-4cb5-9cae-d0f243eb4ab4",
//     duration: "4606",
//     file_path:
//       "users/private/13829/video/Lecture 2  Operators and Conditional Statements  JavaScript Full Course.mp4",
//     user_id: "13829",
//     channel_id: "20",
//     channelIndex: 0,
//     channelPosition: {
//       top: 0,
//       height: 80,
//     },
//     index: 1,
//     public_file_path:
//       "scheduledvideos/13829/20/2024-07-12/Lecture 2  Operators and Conditional Statements  JavaScript Full Course.mp4",
//   },
//   {
//     id: "5571d0c5-eb15-47e2-9489-f2f78626feb1",
//     title: "cloud_user_interface_modal_video",
//     image:
//       "https://api.silocloud.io/get-file-content/users/private/13888/video/thumbnails/cloud_user_interface_modal_video.jpg?expires=1720506860&signature=61351812900484132c3c03c17d97c125a780a89c32514f065d795c5366e31530",
//     since: "2024-07-12T11:00:00",
//     till: "2024-07-12T11:00:30",
//     date: "2024-07-12",
//     channelUuid: "5e766773-4131-459c-9b89-76dcb9bf09db",
//     channel_id: "21",
//     duration: "30",
//     file_path: "users/private/13888/video/cloud_user_interface_modal_video.m4v",
//     user_id: "13888",
//     channelIndex: 0,
//     channelPosition: {
//       top: 0,
//       height: 80,
//     },
//     index: 0,
//     public_file_path:
//       "scheduledvideos/13888/21/2024-07-12/cloud_user_interface_modal_video.m4v",
//   },
//   {
//     id: "d208d16d-cc67-4a99-b05d-7c974d2875bc",
//     title: "Noit Epic A200 Cell Phone",
//     image:
//       "https://api.silocloud.io/get-file-content/users/private/13888/video/thumbnails/Noit%20Epic%20A200%20Cell%20Phone.jpg?expires=1720506860&signature=3272fd551f7d58427d9987ea0a345dbf7efe5b0810f08a69183c08ec7dc6c816",
//     since: "2024-07-12T11:07:00",
//     till: "2024-07-12T11:08:00",
//     date: "2024-07-12",
//     channelUuid: "5e766773-4131-459c-9b89-76dcb9bf09db",
//     channel_id: "21",
//     duration: "60",
//     file_path: "users/private/13888/video/Noit Epic A200 Cell Phone.mp4",
//     user_id: "13888",
//     channelIndex: 0,
//     channelPosition: {
//       top: 0,
//       height: 80,
//     },
//     index: 1,
//     public_file_path:
//       "scheduledvideos/13888/21/2024-07-12/Noit Epic A200 Cell Phone.mp4",
//   },
//   {
//     id: "2bf30d02-38d5-403e-8eba-2f48a5ab130d",
//     title: "Noit Epic A200 Cell Phone",
//     image:
//       "https://api.silocloud.io/get-file-content/users/private/13888/video/thumbnails/Noit%20Epic%20A200%20Cell%20Phone.jpg?expires=1720510243&signature=943c99c0c5207217ff4380fac55b8c184b692e7abadbe5358785afbb221a8e79",
//     since: "2024-07-12T12:06:00",
//     till: "2024-07-12T12:07:00",
//     date: "2024-07-12",
//     channelUuid: "ebe8ef99-77a4-45b1-86e6-a091e0603da4",
//     duration: "60",
//     file_path: "users/private/13888/video/Noit Epic A200 Cell Phone.mp4",
//     user_id: "13888",
//     channel_id: "24",
//     channelIndex: 0,
//     channelPosition: {
//       top: 0,
//       height: 80,
//     },
//     index: 0,
//     public_file_path:
//       "scheduledvideos/13888/24/2024-07-12/Noit Epic A200 Cell Phone.mp4",
//   },
//   {
//     id: "9ed83f9f-eac4-4b4d-8450-532d4aed46d8",
//     title: "Horse Riding _ Cinematic video _ SONY",
//     image:
//       "https://api.silocloud.io/get-file-content/users/private/13888/video/thumbnails/Horse%20Riding%20_%20Cinematic%20video%20_%20SONY.jpg?expires=1720510243&signature=2cbef8a8342a8a0fde7e5150901b3743c57148956b8706df9c520965c27bb9f7",
//     since: "2024-07-12T12:03:00",
//     till: "2024-07-12T12:03:34",
//     date: "2024-07-12",
//     channelUuid: "ebe8ef99-77a4-45b1-86e6-a091e0603da4",
//     duration: "34",
//     file_path:
//       "users/private/13888/video/Horse Riding _ Cinematic video _ SONY.mp4",
//     user_id: "13888",
//     channel_id: "24",
//     channelIndex: 0,
//     channelPosition: {
//       top: 0,
//       height: 80,
//     },
//     index: 1,
//     public_file_path:
//       "scheduledvideos/13888/24/2024-07-12/Horse Riding _ Cinematic video _ SONY.mp4",
//   },
// ];

export const channelData = [
  {
    logo: "https://silocloud.com/assets/frontend/images/silo-home/silocloud.png",
    uuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
    channel_id: 22,
    // index: 1,
  },
  {
    logo: "https://api.silocloud.io/assets/marketplace/stores/RPDigital.png",
    uuid: "ghjnm-eyrtfkjjg-wertgybh",
    channel_id: 21,
    // index: 2,
  },
  {
    logo: "https://kanobetriss.com/images/logo.png",
    uuid: "fvghh-62hfhj-ghvhjvj",
    channel_id: 20,
    // index: 3,
  },
];

export const epgData = [
  // {
  //   channelUuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
  //   id: 1,
  //   since: `${new Date().toISOString().slice(0, 10)}T08:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T09:30:00`,
  //   title: "Nikki Rich at CES?! Find out What You've Been Missing!",
  //   stream_url: "https://youtu.be/RT1TP1JEcNA?si=sTEVfo39fEZoKerC",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
  {
    channelUuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
    id: 2,
    since: `${new Date().toISOString().slice(0, 10)}T09:31:00`,
    till: `${new Date().toISOString().slice(0, 10)}T11:00:00`,
    title: "Nikki Rich at CES?! Find out What You've Been Missing!",
    stream_url: "https://youtu.be/S-EdDTCzUaU?si=RCKqBtT67sxXjLfr",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  },
  {
    channelUuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
    id: 3,
    since: `${new Date().toISOString().slice(0, 10)}T11:01:00`,
    till: `${new Date().toISOString().slice(0, 10)}T12:30:00`,
    title: "New Technology to Combat Dangerous Drones: CES 2023",
    stream_url: "https://youtu.be/gTA5XnQMX1s?si=D-nqm_qzOpygjhg7",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/q9unI9oHyLUNK97RQPYAGjFs4Xu.jpg",
  },
  {
    channelUuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
    id: 4,
    since: `${new Date().toISOString().slice(0, 10)}T12:31:00`,
    till: `${new Date().toISOString().slice(0, 10)}T14:00:00`,
    title:
      "The Future of Autonomous Vehicles: An Interview with Dr. Lael Alexander",
    stream_url: "https://youtu.be/7a_6FqpyrRE?si=m0FMEbiDEkU3CJZW",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/ysaA0BInz4071p3LKqAQnWKZCsK.jpg",
  },
  {
    channelUuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
    id: 5,
    sinceStart: `${new Date().toISOString().slice(0, 10)}T09:31:00`,
    since: `${new Date().toISOString().slice(0, 10)}T14:01:00`,
    till: `${new Date().toISOString().slice(0, 10)}T16:59:00`,
    title:
      "Lael Alexander - Noitavonne/EOC KeyNote Feb 2019 Mobile World Congress",
    stream_url: "https://youtu.be/7hRjoE4j4Is?si=_ThULSOR5KDPNKLe",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/ysaA0BInz4071p3LKqAQnWKZCsK.jpg",
  },
  {
    channelUuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
    id: 6,
    since: `${new Date().toISOString().slice(0, 10)}T16:59:00`,
    till: `${new Date().toISOString().slice(0, 10)}T20:00:00`,
    title:
      "How To Make The Web A Better Place | Dr. Lael Alexander | Poppin Crypto With Cassius Cuvée",
    stream_url: "https://youtu.be/RT1TP1JEcNA?si=sTEVfo39fEZoKerC",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  },
  {
    channelUuid: "10339a4b-7c48-40ab-abad-f3bcaf95d9fa",
    id: 7,
    since: `${new Date().toISOString().slice(0, 10)}T20:00:00`,
    till: `${new Date().toISOString().slice(0, 10)}T24:00:00`,
    title: "Noitavonne, CTIA 2013",
    stream_url: "https://youtu.be/jhkLK_q83Ls?si=hiffFvUOZekzCkez",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/q9unI9oHyLUNK97RQPYAGjFs4Xu.jpg",
  },
  {
    channelUuid: "ghjnm-eyrtfkjjg-wertgybh",
    id: 8,
    since: `${new Date().toISOString().slice(0, 10)}T09:00:00`,
    till: `${new Date().toISOString().slice(0, 10)}T10:00:00`,
    title: "New Technology to Combat Dangerous Drones: CES 2023",
    stream_url: "https://youtu.be/bhpu9RMVoD8?si=0rYpZxum1DK4G-Sh",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/q9unI9oHyLUNK97RQPYAGjFs4Xu.jpg",
  },

  // {
  //   channelUuid: "ghjnm-eyrtfkjjg-wertgybh",
  //   id: 111,
  //   since: `${new Date().toISOString().slice(0, 10)}T14:01:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T15:30:00`,
  //   title: "Nikki Rich at CES?! Find out What You've Been Missing!",
  //   stream_url: "https://youtu.be/7a_6FqpyrRE?si=m0FMEbiDEkU3CJZW",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
  {
    channelUuid: "ghjnm-eyrtfkjjg-wertgybh",
    id: 12,
    sinceStart: `${new Date().toISOString().slice(0, 10)}T09:00:00`,
    since: `${new Date().toISOString().slice(0, 10)}T15:31:00`,
    till: `${new Date().toISOString().slice(0, 10)}T18:00:00`,
    title:
      "Lael Alexander - Noitavonne/EOC KeyNote Feb 2019 Mobile World Congress",
    stream_url: "https://youtu.be/YQlZJrpIOHA?si=zjnkpAFzOmJE5igL",
    image:
      "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/q9unI9oHyLUNK97RQPYAGjFs4Xu.jpg",
  },
  // {
  //   channelUuid: "ghjnm-eyrtfkjjg-wertgybh",
  //   id: 13,
  //   since: `${new Date().toISOString().slice(0, 10)}T17:01:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T18:30:00`,
  //   title:
  //     "The Future of Autonomous Vehicles: An Interview with Dr. Lael Alexander",
  //   stream_url: "https://youtu.be/7a_6FqpyrRE?si=m0FMEbiDEkU3CJZW",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/ysaA0BInz4071p3LKqAQnWKZCsK.jpg",
  // },
  // {
  //   channelUuid: "ghjnm-eyrtfkjjg-wertgybh",
  //   id: 15,
  //   since: `${new Date().toISOString().slice(0, 10)}T18:31:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T20:00:00`,
  //   title:
  //     "The Future of Autonomous Vehicles: An Interview with Dr. Lael Alexander",
  //   stream_url: "https://youtu.be/cDlDshvnfew?si=DK_-G4Ka5Xhqg2gL",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
  // {
  //   channelUuid: "ghjnm-eyrtfkjjg-wertgybh",
  //   id: 16,
  //   since: `${new Date().toISOString().slice(0, 10)}T20:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T22:00:00`,
  //   title: "2017 Black Enterprise Techpreneur of the Year Lael A. Alexander",
  //   stream_url: "https://youtu.be/bb933BvpNSs?si=h5HP6Z9X3d6DxHCG",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
  // {
  //   channelUuid: "ghjnm-eyrtfkjjg-wertgybh",
  //   id: 17,
  //   since: `${new Date().toISOString().slice(0, 10)}T22:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T23:59:00`,
  //   title:
  //     "The Future of Autonomous Vehicles: An Interview with Dr. Lael Alexander",
  //   stream_url: "https://youtu.be/7a_6FqpyrRE?si=m0FMEbiDEkU3CJZW",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 21,
  //   since: `${new Date().toISOString().slice(0, 10)}T08:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T10:00:00`,
  //   title: "Noitavonne, CTIA 2013",
  //   stream_url: "https://youtu.be/7hRjoE4j4Is?si=_ThULSOR5KDPNKLe",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/q9unI9oHyLUNK97RQPYAGjFs4Xu.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 22,
  //   since: `${new Date().toISOString().slice(0, 10)}T10:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T12:00:00`,
  //   title: "Noitavonne agora é Ciaoindustries",
  //   stream_url: "https://youtu.be/YQlZJrpIOHA?si=zjnkpAFzOmJE5igL",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 23,
  //   since: `${new Date().toISOString().slice(0, 10)}T12:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T14:00:00`,
  //   title:
  //     "The Future of Autonomous Vehicles: An Interview with Dr. Lael Alexander",
  //   stream_url: "https://youtu.be/YQlZJrpIOHA?si=zjnkpAFzOmJE5igL",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/ysaA0BInz4071p3LKqAQnWKZCsK.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 24,
  //   since: `${new Date().toISOString().slice(0, 10)}T14:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T16:00:00`,
  //   title: "Nikki Rich at CES?! Find out What You've Been Missing!",
  //   stream_url: "https://youtu.be/RT1TP1JEcNA?si=sTEVfo39fEZoKerC",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/q9unI9oHyLUNK97RQPYAGjFs4Xu.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 25,
  //   since: `${new Date().toISOString().slice(0, 10)}T16:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T18:00:00`,
  //   title: "DR. LAEL ALEXANDER IN ACTION",
  //   stream_url: "https://youtu.be/jhkLK_q83Ls?si=hiffFvUOZekzCkez",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 26,
  //   since: `${new Date().toISOString().slice(0, 10)}T18:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T20:00:00`,
  //   title: "Noitavonne, CTIA 2013",
  //   stream_url: "https://youtu.be/cDlDshvnfew?si=DK_-G4Ka5Xhqg2gL",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/ysaA0BInz4071p3LKqAQnWKZCsK.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 27,
  //   since: `${new Date().toISOString().slice(0, 10)}T20:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T22:00:00`,
  //   title: "DR. LAEL ALEXANDER IN ACTION",
  //   stream_url: "https://youtu.be/RT1TP1JEcNA?si=sTEVfo39fEZoKerC",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/q9unI9oHyLUNK97RQPYAGjFs4Xu.jpg",
  // },
  // {
  //   channelUuid: "fvghh-62hfhj-ghvhjvj",
  //   id: 556,
  //   since: `${new Date().toISOString().slice(0, 10)}T22:00:00`,
  //   till: `${new Date().toISOString().slice(0, 10)}T24:00:00`,
  //   title: "Nikki Rich at CES?! Find out What You've Been Missing!",
  //   stream_url: "https://youtu.be/cDlDshvnfew?si=DK_-G4Ka5Xhqg2gL",
  //   image:
  //     "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
  // },
];
