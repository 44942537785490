import { useEffect, useState } from "react";

const useUserInteraction = () => {
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const handleInteraction = () => setHasInteracted(true);

    // List of events to listen for user interaction
    const events = ["click", "keypress"];

    events.forEach((event) => {
      window.addEventListener(event, handleInteraction, { once: true });
    });

    // Cleanup event listeners on component unmount
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleInteraction);
      });
    };
  }, []);

  return hasInteracted;
};

export default useUserInteraction;
