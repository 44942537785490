import React, { useEffect, createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import "../css/streamdeck_css/streamdeck.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import jwt from 'jsonwebtoken';
export const GloabalContext = createContext();
const Layout = ({ title, app, ...props }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  return (
    <>
      <GloabalContext.Provider value={{ files, setFiles }}>
        <Head title={!title && "SiloCloud"} />
        <ToastContainer hideProgressBar autoClose={3000} />
        <AppRoot>
          <AppMain>
            <AppWrap>
              <Header fixed />
              <Outlet />
              <Footer />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;
