import React from "react";
import { useTheme, useThemeUpdate } from "../../../layout/provider/Theme";
import "../../../css/common.css";
import { Icon } from "../../../components/Component";
import Cursor from "quill/blots/cursor";
// import "../../../css/tvsilocloud/app.css";

function CustomFooter({ text }) {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  function shareCurrentWebsite() {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Check out this website:",
          url: window.location.href,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      console.error("Web Share API not supported in this browser.");
      // Fallback for browsers that do not support the Web Share API
      alert(
        "Sharing is not supported in your browser. Please copy and share the URL manually."
      );
    }
  }

  if (theme.fullScreen) {
    return null;
  }

  return (
    <>
      <section
        className={`w-100 position-fixed bottom-0 start-0 py-1 section-footer`}
        style={{ zIndex: 100 }}
      >
        <div className="container-fluid">
          <div className="row px-5 py-1 flex-row justify-content-between align-items-center">
            <p
              style={{ fontSize: "1.1rem", width: "fit-content", margin: "0" }}
            >
              {text}
            </p>
            <div
              style={{
                fontSize: "1.4rem",
                width: "fit-content",
                marginLeft: "auto",
              }}
            >
              <Icon
                name="share"
                style={{ cursor: "pointer" }}
                title="share"
                onClick={shareCurrentWebsite}
              />
            </div>
            <p style={{ width: "fit-content" }}>Powered by SiloCloud</p>
            {/* <div
              style={{ width: "fit-content" }}
              className="flex-row align-items-center"
            ></div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomFooter;
