import React, { useEffect, useRef, useState } from "react";
import EpgComponent from "./EpgComponent";
import "../../../css/tvsilocloud/app.css";
import ReactPlayer from "react-player";
import { channelData, epgData } from "../../helpers/extraPropertise";

import video2 from "../../assets/sample-video.mp4";

import { getChannelData, getGroupData } from "../../../utils/channelEpg";

import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { createGlobalStyle } from "styled-components";

import { useTheme, useThemeUpdate } from "../../../layout/provider/Theme";
import "./player.css";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { increaseView } from "./increaseView";

import tv_logo from "../../../images/applogos/tv_logo.png";
import VideoPlayer from "./VideoPlayer";
import useUserInteraction from "../hooks/useUserInteraction";

function getVdoSrc(allVideos = [], channel_id) {
  for (const video of allVideos) {
    if (video?.channel_id === channel_id) {
      return video.playlistpathLink;
    }
  }
  return null;
}
function getCurrentEpg(epg, channel_id) {
  return epg.filter((item) => item.channel_id == channel_id);
}
function updateUrl(id) {
  const newUrl = `${window.location.origin}${window.location.pathname}?id=${id}`;
  window.history.pushState({ path: newUrl }, "", newUrl);
}

function Watch({ did }) {
  const [viewIncreased, setViewIncreased] = useState(false);

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  // const channels = React.useMemo(() => channelData, []);

  // const epg = React.useMemo(() => epgData, []);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const playerRef = useRef(null);
  const [startTimeInSeconds, setStartTimeInSeconds] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const [vSrc2, setVSrc2] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [allvideos, setAllvideos] = useState([]);
  const [channels, setChannels] = useState([]);
  const [rawEpg, setRawEpg] = useState([]);
  const [epg, setEpg] = useState([]);

  const [currentChannel, setCurrentChannel] = useState(null);
  const [currentEpg, setCurrentEpg] = useState([]);

  const [streamEnded, setStreamEnded] = useState(false);
  const [streamNotStarted, setStreamNotStarted] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [countdownOver, setCountdownOver] = useState(false);

  const [currentVideoTitle, setCurrentVideoTitle] = useState(null);

  const [isMute, setIsMute] = useState(true);

  // const handleReady = () => {
  //   if (videoSrc) {
  //     if (startTimeInSeconds > playerRef?.current?.duration)
  //       setStreamEnded(false);
  //     else playerRef?.current?.seekTo(startTimeInSeconds);
  //   }
  // };

  const hasInteracted = useUserInteraction();

  useEffect(() => {
    setViewIncreased(false);
  }, [currentChannel]);

  useEffect(() => {
    getGroupData(did).then((data) => {
      if (data.status) setData(data.data);
      else {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (data) {
      setEpg(data.epg_data);
      setRawEpg(data.epg_data);

      setChannels(data.channelData);
      setAllvideos(data.playlistpathLinks);

      setCurrentChannel(data.channelData[0]);

      // setEpg(epgData);
      // setChannels(channelData);
      // setCurrentChannel(channelData[0]);

      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      // console.log(data);
      const filterByChannelId = (epgData, channelId) => {
        return epgData.filter((item) => item.channel_id == channelId);
      };

      var newEpg = [];

      channels.forEach((c) => {
        const filteredEpg = filterByChannelId(rawEpg, c.channel_id);
        // console.log(filteredEpg);

        function addSinceVideoStart(rawData) {
          if (rawData.length > 0) {
            const sinceVideoStart = rawData[0].since; // Assuming the first since time as sinceVideoStart for all entries

            return rawData.map((entry) => ({
              ...entry,
              sinceVideoStart,
            }));
          } else {
            return rawData;
          }
        }

        const modifiedEpg = addSinceVideoStart(filteredEpg);

        const filledEpg = modifiedEpg.length
          ? generateFullDayEPG(modifiedEpg)
          : [];

        newEpg = [...newEpg, ...filledEpg];

        function generateFullDayEPG(rawData) {
          const ONE_DAY = 24 * 60 * 60 * 1000; // milliseconds in one day

          // Start time based on the first EPG entry
          const startTime = new Date(rawData[0].since).getTime();

          // End time set to today's midnight
          let endDate = new Date();
          endDate.setHours(24, 0, 0, 0); // Set to midnight of the next day
          const endTime = endDate.getTime();

          let currentTime = startTime;
          let newEPGData = [];

          let initialSinceVideoStart = new Date(
            rawData[0].sinceVideoStart
          ).getTime();

          while (currentTime < endTime) {
            rawData.forEach((entry, index) => {
              let newEntry = { ...entry };
              let entryDuration = new Date(entry.till) - new Date(entry.since);

              newEntry.since = new Date(currentTime).toISOString();
              newEntry.till = new Date(
                currentTime + entryDuration
              ).toISOString();
              newEntry.sinceVideoStart = new Date(
                initialSinceVideoStart
              ).toISOString();

              newEPGData.push(newEntry);

              currentTime += entryDuration;

              // Handle the gap between current entry and the next original entry
              if (index < rawData.length - 1) {
                let nextEntryStart = new Date(
                  rawData[index + 1].since
                ).getTime();
                let currentEntryEnd = new Date(rawData[index].till).getTime();
                let gap = nextEntryStart - currentEntryEnd;
                currentTime += gap;
              }

              if (currentTime >= endTime) {
                return;
              }
            });

            // Calculate total duration of the original schedule including gaps
            initialSinceVideoStart += rawData.reduce((acc, entry, index) => {
              let entryDuration = new Date(entry.till) - new Date(entry.since);
              acc += entryDuration;

              // Add gap duration if not the last entry
              if (index < rawData.length - 1) {
                let nextEntryStart = new Date(
                  rawData[index + 1].since
                ).getTime();
                let currentEntryEnd = new Date(rawData[index].till).getTime();
                acc += nextEntryStart - currentEntryEnd;
              }

              return acc;
            }, 0);
          }

          // console.log(newEPGData);
          return newEPGData;
        }
      });

      setEpg(newEpg);
      // console.log(newEpg);
      // setEpg(rawEpg);
    }
  }, [rawEpg, channels]);

  useEffect(() => {
    if (data && channels) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id");
      if (id) {
        const channel = channels.find((channel) => channel.channel_id == id);
        if (channel) {
          setCurrentChannel(channel);
        } else setCurrentChannel(data.channelData[0]);
      } else setCurrentChannel(data.channelData[0]);
    }
  }, [channels]);

  useEffect(() => {
    setVideoSrc(getVdoSrc(allvideos, currentChannel?.channel_id));
    setCurrentEpg(getCurrentEpg(epg, currentChannel?.channel_id));
    setVSrc2((prev) => {
      return video2;
    });

    if (currentChannel?.channel_id) updateUrl(currentChannel.channel_id);
  }, [currentChannel, countdownOver, epg]);

  useEffect(() => {
    const checkCurrentVideo = () => {
      const currentTime = new Date();

      const sortedVideos = currentEpg.sort(
        (a, b) => new Date(a.since) - new Date(b.since)
      );

      const startedVideos = sortedVideos.filter(
        (video) => new Date(video.since) <= currentTime
      );

      const earliestStartedVideo = startedVideos[0];
      const latestVideo = sortedVideos[sortedVideos.length - 1];
      const firstVideo = sortedVideos[0];

      const currentVideo = startedVideos.find((video, index) => {
        const nextVideo = startedVideos[index + 1];
        return (
          currentTime >= new Date(video.since) &&
          (!nextVideo || currentTime < new Date(nextVideo.since))
        );
      });

      if (firstVideo && new Date(firstVideo.since) > currentTime) {
        setStreamNotStarted(true);

        const startTime = new Date(firstVideo.since);
        const countdownTime = (startTime - currentTime) / 1000; // Time in seconds

        setCountdown(countdownTime);
        setVideoSrc(null);
      } else {
        setStreamNotStarted(false);
        setCountdown(null);
      }

      // if (earliestStartedVideo) {
      //   const startTime = new Date(earliestStartedVideo.since);
      //   const gap = (currentTime - startTime) / 1000; // Gap in seconds
      //   setStartTimeInSeconds(gap);
      //   setStreamNotStarted(false);
      // }

      if (currentVideo) {
        // const startTime = new Date(currentVideo.since);
        setCurrentVideoTitle(currentVideo.title);
        const startTime = new Date(currentVideo.sinceVideoStart);
        const gap = (currentTime - startTime) / 1000; // Gap in seconds
        setStartTimeInSeconds(gap);
        setStreamNotStarted(false);
      }

      if (latestVideo) {
        const endTime = new Date(latestVideo.till);
        if (currentTime > endTime) {
          setStreamEnded(true);
          setVideoSrc(null);
        } else {
          setStreamEnded(false);
        }
      }
    };

    checkCurrentVideo();
  }, [currentEpg, countdownOver, currentChannel, videoSrc]);

  useEffect(() => {
    setVideoSrc(getVdoSrc(allvideos, currentChannel?.channel_id));
  }, [streamNotStarted, streamEnded, videoSrc]);

  useEffect(() => {
    let countdownInterval;

    if (streamNotStarted && countdown !== null) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            setCountdownOver(true);
            return null;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [streamNotStarted, countdown]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOnPlay = () => {
    if (!viewIncreased) {
      setTimeout(() => {
        increaseView(currentChannel);
        setViewIncreased(true);
      }, 10000); // Increase views after 10 seconds
    }
  };

  if (isLoading) {
    return (
      <>
        <div
          className="epgSection main-tv-box  p-0"
          style={{
            marginTop: `${theme.fullScreen ? "0px" : "52px"}`,
          }}
        >
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <p>
              <Skeleton
                style={{
                  height: `${dimensions.height / 2}px`,
                }}
              />
            </p>
          </SkeletonTheme>
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <p>
              <Skeleton height={40} />
            </p>
          </SkeletonTheme>
          <div
            className="epg-box"
            style={{
              height: "330px",
              marginBottom: `${theme.fullScreen ? "20px" : "85px"}`,
            }}
          >
            <EpgComponent
              isLoading={true}
              epg={[]}
              channels={[]}
              setCurrentChannel={setCurrentChannel}
              currentChannel={currentChannel}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      className="epgSection main-tv-box  p-0"
      style={{
        marginTop: `${theme.fullScreen ? "0px" : "52px"}`,
      }}
    >
      <div
        style={
          {
            // textAlign: "center",
            // display: "flex",
            // justifyContent: "center",
            // width: `${dimensions.width - 50}px`,
          }
        }
      >
        <div
          className="video-box"
          style={{
            position: "relative",
            height: `${
              theme.fullScreen ? dimensions.height : dimensions.height / 1.5
            }px`,
          }}
        >
          {/* {streamEnded && (
          <div
            style={{
              position: "absolute",
              bottom: "2%",
              right: "2%",
              opacity: "0.6",
              zIndex: 1,
            }}
          >
            <h1 className="tv-msg-text">
              Stream ended. Next stream will be live soon...
            </h1>
          </div>
        )} */}

          {!streamNotStarted && !streamEnded && videoSrc ? (
            <>
              <VideoPlayer
                key={videoSrc}
                currentChannel={currentChannel}
                videoSrc={videoSrc}
                handleOnPlay={handleOnPlay}
                setStreamEnded={setStreamEnded}
                startTimeInSeconds={startTimeInSeconds}
                muted={!hasInteracted || isMute}
                isMute={isMute}
                setIsMute={setIsMute}
              />
              {/* <MediaPlayer
                // aspectRatio="16/9"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                title={currentChannel.channel_name}
                playsInline
                src={videoSrc}
                autoPlay
                muted
                loop
                onPlay={() => {
                  if (!viewIncreased) {
                    setTimeout(() => {
                      increaseView(currentChannel);
                      setViewIncreased(true);
                    }, 10000); // Increase views after 10 seconds
                  }
                }}
                onEnded={() => {
                  setStreamEnded(true);
                  console.log("stream end");
                }}
                fullscreenOrientation="none"
                onFullscreenChange={() => {
                  themeUpdate.fullScreen(false);
                }}
                keyDisabled
                {...(currentChannel.is_live
                  ? {
                      streamType: "live",
                    }
                  : {
                      currentTime: startTimeInSeconds,
                    })}
              >
                <MediaProvider />
                <DefaultVideoLayout
                  // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                  icons={defaultLayoutIcons}
                  disableTimeSlider="true"
                  playbackRates={{
                    min: 1,
                    max: 1,
                    step: 0,
                  }}
                />
              </MediaPlayer> */}
            </>
          ) : (
            <>
              <MediaPlayer
                aspectRatio="16/9"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                title=""
                // src="https://files.vidstack.io/sprite-fight/hls/stream.m3u8"
                src={vSrc2}
                autoPlay
                muted
                loop
                fullscreenOrientation="none"
              >
                <MediaProvider />
                {/* <DefaultVideoLayout
                // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                icons={defaultLayoutIcons}
              /> */}
              </MediaPlayer>
            </>
          )}

          {streamNotStarted && countdown !== null ? (
            <div
              style={{
                position: "absolute",
                bottom: "2%",
                right: "2%",
                opacity: "0.6",
                zIndex: 1,
              }}
            >
              <h1 className="tv-msg-text">
                Get Set! {Math.floor(countdown / 3600)}:
                {Math.floor((countdown % 3600) / 60)}:
                {Math.floor(countdown % 60)
                  .toString()
                  .padStart(2, "0")}
              </h1>
            </div>
          ) : null}

          <div
            className="channel-logo-view position-absolute"
            style={{ top: "2%", left: "2%", height: "50px", width: "50px" }}
          >
            {currentChannel?.logo && (
              <img src={currentChannel?.logo} alt="channel_logo" />
            )}
          </div>
        </div>
        {currentVideoTitle && (
          <div
            style={{
              display: "flex",
              gap: "7px",
              alignItems: "center",
              padding: "10px 20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                paddingBottom: "1.5px",
              }}
            >
              <img
                className="channel-logo"
                src={currentChannel.logo || tv_logo}
                style={{
                  borderRadius: "50%",
                  height: "40px",
                  width: "40px",
                  objectFit: "contain",
                }}
              />
              <h5>{currentChannel.channel_name}</h5>
            </div>
            <span>-</span>
            <p style={{ fontSize: "1.1rem" }}>{currentVideoTitle}</p>
          </div>
        )}
      </div>
      <div
        className="epg-box position-relative"
        style={{
          height: "330px",
          marginBottom: `${theme.fullScreen ? "2px" : "75px"}`,
        }}
      >
        {epg.length && channels.length && rawEpg.length ? (
          <EpgComponent
            isLoading={isLoading}
            epg={epg}
            channels={channels}
            setCurrentChannel={setCurrentChannel}
            currentChannel={currentChannel}
          />
        ) : (
          <>
            <h2 className="center mt-4 mb-4 tv-msg-text">
              Amazing stuff going live soon! Tune in for some fun.
            </h2>
            <EpgComponent
              isLoading={true}
              epg={[]}
              channels={channels}
              setCurrentChannel={setCurrentChannel}
              currentChannel={currentChannel}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Watch;
