// const data = {
//   title: "Notifications",
//   notification: [
//     {
//       id: "note106",
//       icon: "curve-down-right",
//       iconStyle: "bg-warning-dim",
//       text: "You have requested to Widthdrawl",
//       time: "2 hrs ago",
//     },
//     {
//       id: "note105",
//       icon: "curve-down-left",
//       iconStyle: "bg-success-dim",
//       text: "Your Deposit Order is placed",
//       time: "5 hrs ago",
//     },
//     {
//       id: "note104",
//       icon: "curve-down-right",
//       iconStyle: "bg-warning-dim",
//       text: "You have requested to Widthdrawl",
//       time: "7 hrs ago",
//     },
//     {
//       id: "note103",
//       icon: "curve-down-left",
//       iconStyle: "bg-success-dim",
//       text: "Your Deposit Order is placed",
//       time: "8 hrs ago",
//     },
//     {
//       id: "note102",
//       icon: "curve-down-right",
//       iconStyle: "bg-warning-dim",
//       text: "You have requested to Widthdrawl",
//       time: "11 hrs ago",
//     },
//     {
//       id: "note101",
//       icon: "curve-down-left",
//       iconStyle: "bg-success-dim",
//       text: "Your Deposit Order is placed",
//       time: "12 hrs ago",
//     },
//   ],
// };

const data = {
  title: "Notifications",
  notification: [],
};
export default data;
